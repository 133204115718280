import Button from 'Atoms/Buttons/Button';
import Grid from 'Atoms/Grids/Grid';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import { GridSize } from 'Enums/GridSize.enum';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import OrderConfirmationPageModel from 'Models/Pages/OrderConfirmationPage/OrderConfirmationPageModel.interface';
import CheckoutInfoCard from 'Molecules/CheckoutInfoCard/CheckoutInfoCard';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import ContentArea from 'Organisms/ContentArea/ContentArea';
import SmallHeroBlock from 'Organisms/Blocks/SmallHeroBlock';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import useMedia from 'Shared/Hooks/useMedia';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import XHtmlArea from '../../Molecules/XHtmlArea/XHtmlArea';
import OrderList from './OrderList';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TagManager from 'react-gtm-module'; // no typescript support

function OrderConfirmationPage() {
  const {
    order,
    heroHeader,
    inEditMode,
    heroImage,
    orderBody,
    customerSupportPhoneNumber,
    customerSupportEmail,
    customerSupportHours,
    contentArea,
    promotions,
    purchasedItems,
  } = useCurrentPage<OrderConfirmationPageModel>();
  const kexNavigate = useKexNavigate();
  const {
    checkoutLabels: {
      orderReceived,
      payment,
      delivery,
      customerSupport,
      goToStartPage,
    },
  } = useTranslationData();

  const { languageRoute } = useAppSettingsData();
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);

  const goToStartPageAction = () => {
    kexNavigate(`/${languageRoute}`);
  };

  TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  TagManager.dataLayer({
    dataLayer: {
      event: 'purchase',
      ecommerce: {
        transaction_id: order.orderNumber,
        value: order.totalPrice.price,
        tax: order.totalVATPrice.price,
        shipping: order.totalFreightPrice.price,
        currency: order.currency,
        items: purchasedItems.map((x) => {
          return {
            item_id: x.articleNumber,
            item_name: x.name,
            price: x.nowPrice.price,
            quantity: x.quantity,
          };
        }),
      },
    },
  });

  return (
    <>
      <SmallHeroBlock
        header={heroHeader}
        inEditMode={inEditMode}
        image={heroImage}
      />

      <ContentContainer width="content" noPadding={isMobile}>
        <GridContainer>
          <CardContainer>
            <Row>
              <CheckoutInfoCard heading={orderReceived} large>
                <XHtmlArea content={orderBody}></XHtmlArea>
              </CheckoutInfoCard>
            </Row>
            <Row>
              <CheckoutInfoCard heading={payment} iconType="CreditCard">
                {order.paymentMethods.map((p, i) => (
                  <Paragraph size="s" key={`${p}${i}`}>
                    {p}
                  </Paragraph>
                ))}
              </CheckoutInfoCard>

              <CheckoutInfoCard heading={delivery} iconType="Storage">
                <Paragraph size="s">{order.shippingMethod}</Paragraph>
              </CheckoutInfoCard>

              <CheckoutInfoCard heading={customerSupport} iconType="Support">
                <div>
                  <InfoLine> {customerSupportPhoneNumber}</InfoLine>
                  <InfoLine> {customerSupportEmail}</InfoLine>
                  <InfoLine>{customerSupportHours}</InfoLine>
                </div>
              </CheckoutInfoCard>
            </Row>
          </CardContainer>

          <RightWrapper>
            <OrderList
              isLoading={false}
              order={order}
              allowEdit={false}
              promotions={promotions}
            />
            <ButtonWrapper>
              <Button
                type="secondary"
                size="s"
                onClick={goToStartPageAction}
                fullWidth={isMobile}
              >
                {goToStartPage}
              </Button>
            </ButtonWrapper>
          </RightWrapper>
        </GridContainer>
      </ContentContainer>

      <ContentContainer noPadding>
        <Grid
          type={GridSize.One}
          data-content-area
          {...applyEditModeAttr(inEditMode && 'MainContent')}
        >
          {Array.isArray(contentArea) && (
            <ContentArea childItems={contentArea} />
          )}
        </Grid>
      </ContentContainer>
    </>
  );
}

export default OrderConfirmationPage;

const GridContainer = styled('div', {
  display: 'grid',
  g: 4,
  gridTemplateColumns: '1fr',
  wh: '100%',
  justifyContent: 'center',
  my: 4,

  '@mediaMinHuge': {
    gridTemplateColumns: '6.5fr 3.5fr',
    g: 8,
    my: 16,
    mx: 'auto',
  },
  mb: 30,
});

const InfoLine = styled('p', { mb: 2, color: '$textPrimary' });

const ButtonWrapper = styled('div', {
  pt: 12,
  mx: 4,
  '@mediaMinLarge': {
    mt: 'auto',
    ml: 'auto',
  },
});

const RightWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const Row = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  '@mediaMinLarge': {
    flexWrap: 'noWrap',
  },
  g: 4,
});

const CardContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  g: 4,
});
