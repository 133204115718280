import { styled } from 'stitches.config';
import KexIconLoader, { KexIconType } from 'Kex/KexIconLoader';
import BulletPoint from 'Atoms/BulletPoint/BulletPoint';
import { ReactNode } from 'react';

type PropTypes = {
  heading: string;
  children: ReactNode | undefined;
  iconType?: KexIconType;
  large?: boolean;
};

const CheckoutInfoCard = ({
  heading,
  children,
  iconType,
  large = false,
}: PropTypes) => {
  const Icon = iconType && KexIconLoader(iconType);
  return (
    <Wrapper>
      <TopWrapper>
        {large ? (
          <BulletPoint
            text={heading}
            backgroundColor="primary"
            css={{ fs: 12 }}
            large
          ></BulletPoint>
        ) : (
          <>
            {Icon && <Icon size={'m'} color={'primary'} />}
            <Heading large={large}> {heading} </Heading>
          </>
        )}
      </TopWrapper>
      {children}
    </Wrapper>
  );
};
const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  w: '100%',
  p: 4,
  '@mediaMinLarge': {
    p: 8,
  },
  backgroundColor: '$checkoutPageBackgroundSecondary',
});

const TopWrapper = styled('div', {
  display: 'flex',
  mb: 6,
});

const Heading = styled('h6', {
  my: 'auto',
  color: '$textPrimary',
  letterSpacing: '$ls1',
  wordSpacing: '$wordSpacings$fontPrimary',
  textTransform: 'uppercase',
  fontFamily: '$fontPrimary500',
  fs: 6,
  lineHeight: '$lh12',
  '@mediaMinLarge': {
    fs: 8,
    lineHeight: '$lh125',
  },
  ml: 2,
  variants: { large: { true: { fs: 12 } } },
});

export default CheckoutInfoCard;
