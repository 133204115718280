import { H4 } from 'Atoms/Typography/Headings/Heading';
import { styled } from 'stitches.config';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import PriceModel from 'Models/Price/PriceModel.interface';
import { ReactNode } from 'react';

type PropTypes = {
  totalVATPrice: PriceModel;
  totalPrice: PriceModel;
  totalFreightPrice: PriceModel;
  totalPriceForItems: PriceModel;
  hasReachedFreeFreight: boolean;
  renderPromotions?: ReactNode;
  shippingOptionId?: string;
  isOrderConfirmation?: true;
};

function CartPaymentInformation({
  totalVATPrice,
  totalPrice,
  totalFreightPrice,
  totalPriceForItems,
  hasReachedFreeFreight,
  renderPromotions,
  shippingOptionId = '',
  isOrderConfirmation,
}: PropTypes) {
  const {
    checkoutLabels: { sumItems, vat, total, shipping, freeShipping },
  } = useTranslationData();

  // Only use freeshipping text if we have reached the
  // free shipping limit _and_ choosen a deliveryOption
  let isFreeShipping = !!(hasReachedFreeFreight && shippingOptionId);

  // If this component is used in orderConfirmation page, ignore shippingOptionId.
  if (isOrderConfirmation) {
    isFreeShipping = hasReachedFreeFreight;
  }

  return (
    <Payment>
      <PaymentInformation>
        <PriceColumn>
          <span>{sumItems}:</span>
          <span>{totalPriceForItems.priceRoundedAsString}</span>
        </PriceColumn>

        {renderPromotions}

        <PriceColumn isDiscount={isFreeShipping}>
          <span>{shipping}:</span>
          <span>
            {isFreeShipping
              ? freeShipping
              : totalFreightPrice.priceRoundedAsString}
          </span>
        </PriceColumn>

        <PriceColumn>
          <span>{vat}:</span>
          <span>{totalVATPrice.priceRoundedAsString}</span>
        </PriceColumn>

        <PriceColumn>
          <H4>{total}:</H4>
          <H4>{totalPrice.priceRoundedAsString}</H4>
        </PriceColumn>
      </PaymentInformation>
    </Payment>
  );
}

const Payment = styled('div', {
  mt: 8,
  fw: '$fw300',
  color: '$grey900',
  w: '100%',
});

export const PriceColumn = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  px: 0,
  mb: 3,
  '&:last-child': {
    mb: 0,
  },
  variants: {
    isDiscount: {
      true: {
        color: '$JE68GreenPrimary',
      },
    },
  },
});

const PaymentInformation = styled(
  'div',
  {
    mt: 0,
    fw: '$fw300',
    color: '$paymentInfoTextPrimary',
    backgroundColor: '$checkoutPageBackgroundSecondary',
    '@mediaMinMedium': {
      position: 'sticky',
      t: 25,
    },
  },
  {
    '@mediaMinMedium': {
      position: 'webkit-sticky',
      t: 25,
    },
  }
);

export default CartPaymentInformation;
