import ProductList from 'Organisms/ProductList/ProductList';
import { styled } from 'stitches.config';
import CartPaymentInformation, {
  PriceColumn,
} from 'Molecules/PaymentInformation/PaymentInformation';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { H3 } from 'Atoms/Typography/Headings/Heading';
import Divider from 'Atoms/Divider/Divider';

import OrderModel from 'Models/Order/OrderModel.interface';
import PromotionModel from 'Promotions/PromotionModel.interface';

type PropType = {
  order: OrderModel;
  promotions: PromotionModel[];
  isLoading: boolean;
  allowEdit?: boolean;
  noPadding?: boolean;
};

function OrderList({ order, promotions, isLoading }: PropType) {
  const {
    checkoutLabels: { yourOrder },
  } = useTranslationData();

  const {
    orderItems,
    totalPrice,
    totalVATPrice,
    totalFreightPrice,
    totalPriceForItems,
    hasReachedFreeFreight,
  } = order;

  return (
    <Main>
      <CartContainer>
        <>
          <CartContent>
            <PaddingWrapper>
              <Wrapper>
                <H3 css={HeadingStyle}>{yourOrder}</H3>
              </Wrapper>
            </PaddingWrapper>
            <ProductListWrapper>
              <ProductList
                products={orderItems}
                isLoading={isLoading}
                allowEdit={false}
                isMiniCart={false}
              />
            </ProductListWrapper>
          </CartContent>
          <PaddingWrapper>
            <Divider type="max" css={{ m: 0 }} />
            <CartPaymentInformation
              isOrderConfirmation
              totalPrice={totalPrice}
              totalVATPrice={totalVATPrice}
              totalFreightPrice={totalFreightPrice}
              totalPriceForItems={totalPriceForItems}
              hasReachedFreeFreight={hasReachedFreeFreight}
              renderPromotions={
                <>
                  {promotions.map((p, i) => {
                    return (
                      <PriceColumn isDiscount key={`${p.promotionName}${i}`}>
                        <span>{p.promotionName}</span>
                        <span>
                          {p.promotionDiscountAmount.priceRoundedAsString}
                        </span>
                      </PriceColumn>
                    );
                  })}
                </>
              }
            />
          </PaddingWrapper>
        </>
      </CartContainer>
    </Main>
  );
}

const CartContainer = styled(
  'div',
  {
    display: 'flex',
    flexDirection: 'column',
    w: '100%',
    h: 'auto',
    justifyContent: 'center',
    my: 0,
    mx: 0,
    py: 8,
    backgroundColor: '$checkoutPageBackgroundSecondary',
    '@mediaMinLarge': {
      maxW: 132,
    },
    '@mediaMinMedium': {
      position: 'sticky',
      t: 24,
    },
    variants: {
      noPadding: {
        true: {
          px: 0,
        },
      },
    },
  },
  {
    '@mediaMinMedium': {
      position: 'webkit-sticky',
      t: 24,
    },
  }
);

const PaddingWrapper = styled('div', {
  px: 4,
  '@mediaMinLarge': {
    px: 8,
  },
});

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const CartContent = styled('div', {
  fontWeight: '$fw300',
  wh: '100%',
  color: '$cartContentTextPrimary',
  mt: 0,
});

const ProductListWrapper = styled('div', {});

const Main = styled('div', {
  backgroundColor: '$cartArticlesBackgroundPrimary',
  height: 'auto',
});

const HeadingStyle = {
  mb: 3,
  mt: 0,
};

export default OrderList;
